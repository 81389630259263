/***
 * based on vuelidate documentation
 * @see https://vuelidate-next.netlify.app/advanced_usage.html#i18n-support
 *
 */

import * as vv from '@vuelidate/validators';
import { createI18nMessage, MessageProps } from '@vuelidate/validators';
import { i18n } from '@/lang';
import { containsStr } from '@/components/utils';
// change the path for fetching validator messages
const messagePath = ({ $validator }: MessageProps) => `SUI.validations.${$validator}`;
//const messageParams =;

// function responsible for doing the actual translation
const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n), messagePath });

// wrap each validator.
export const required = withI18nMessage(vv.required);
export const numeric = withI18nMessage(vv.numeric);
export const hasLength = (strLength: number) => vv.helpers.withParams(
   { chars: strLength },
   withI18nMessage((val: string) => vv.helpers.len(val) === strLength)
);
export const minLength = withI18nMessage(vv.minLength, { withArguments: true });
export const maxLength = withI18nMessage(vv.maxLength, { withArguments: true });
export const sameAs = withI18nMessage(vv.sameAs, { withArguments: true });
export const validEmail = withI18nMessage(vv.email);
export const validOTP = withI18nMessage(vv.helpers.regex(/^\d{6}$/));

export const languageCode = withI18nMessage((val: string) => !vv.helpers.req(val) || vv.helpers.regex('alpha', /^[a-z]{2}(-[A-Z]{2})?$/));

// password policies - all in one
//const pwPolicies = vv.helpers.regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!&*\-+=();."'?$%~#<>:,|§\\/@^_\[\]{}])(?=.*[0-9])[\w\p{L}!&*\-+=();."'?$%~#<>:,|§\\/@^_\[\]{}]{12,}$/u)
//export const passwordPolicies = withI18nMessage(pwPolicies)

/** contains at least 1 lowercase letter */
export const hasLowercase = withI18nMessage(vv.helpers.regex(/^(?=.*[a-z]).*$/u));

/** contains at least 1 uppercase letter */
export const hasUppercase = withI18nMessage(vv.helpers.regex(/^(?=.*[A-Z]).*$/u));

/** contains at least 1 digit */
export const hasDigit = withI18nMessage(vv.helpers.regex(/^(?=.*[0-9]).*$/u));

/** contains at least 1 special character */
export const hasSpecial = (val: string) => vv.helpers.withParams(
   { chars: val },
   withI18nMessage(vv.helpers.regex(/^(?=.*[!&*\-+=();."'?$%~#<>:,|§\\/@^_[\]{}]).*$/u))
);

/** does not contain invalid characters */
export const invalidCharacters = withI18nMessage(vv.helpers.regex(/^[\w\p{L}!&*\-+=();."'?$%~#<>:,|§\\/@^_[\]{}]*$/u));

/** is true */
export const tcAccepted = (tcRequired: boolean) => !tcRequired || withI18nMessage(vv.helpers.regex(/^true$/ui));

function reverse(s: string) {
   return [...s].reverse()
      .join('');
}

/**
 * Copies logic from
 * <i>org.opends.server.extensions.AttributeValuePasswordValidator.attributeContainsPasswordSubstring()</i>
 * found in <i>org.forgerock.opendj:opendj-server:7.4.0</>
 */
function attributeContainsPasswordSubstring(attr: string, passwordVal: string, minSubstringLength: number = 5) {
   const password = passwordVal.toLowerCase();
   const passwordLength = password.length;

   for (let i = 0; i < passwordLength; ++i) {
      for (let j = i + minSubstringLength; j <= passwordLength; ++j) {

         const attrValue = attr.toLowerCase();
         if (containsStr(attrValue, password.substring(i, j))) {
            return true;
         }
      }
   }

   return false;
}

/**
 * Copies logic from
 * <i>org.opends.server.extensions.AttributeValuePasswordValidator.passwordContainsAttributeValuesSubstring()</i>
 * found in <i>org.forgerock.opendj:opendj-server:7.4.0</>
 */
function passwordContainsAttributeValuesSubstring(passwordVal: string, attr: string, minSubstringLength: number = 5) {
   const password = passwordVal.toLowerCase();
   const stringAttrValue = attr.toLowerCase();
   const valueLength = stringAttrValue.length;

   for (let i = 0; i < valueLength; ++i) {
      for (let j = i + minSubstringLength; j <= valueLength; ++j) {
         if (containsStr(password, stringAttrValue.substring(i, j))) {
            return true;
         }
      }
   }

   return false;
}

/** cannot use the email or portions of it */
export const notSameAsEmail = (email?: string) => vv.helpers.withParams(
   { chars: email },
   withI18nMessage((value: string) => {
         if (!email || !value) return false;
         email = email.toLowerCase();
         value = value.toLowerCase();
         return (email !== value && email !== reverse(value)
               && !attributeContainsPasswordSubstring(email, value))
            && !passwordContainsAttributeValuesSubstring(value, email);
      }
   )
);
