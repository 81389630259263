import { Popover } from 'bootstrap';

export const initPopovers = () => {
   const triggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
   return [...triggerList].map((el) => new Popover(el));
};

export function containsStr(str?: string | null, substr?: string | null): boolean {
   if (str === substr) return true;
   if (str === undefined || str === null || substr === undefined || substr === null) return false;
   return str.toLowerCase()
      .includes(substr.toLowerCase());
}
